<template>
	<div class="nav">
		<div class="container">
			<img
				class="logo"
				src="@/assets/9916119.png" 
				alt="首页"
				@click.stop="handlePush"
			/>
			<div class="menu">
				<div 
					class="menu-item"
					:class="$route.path === item.path?'active':''"
					v-for="(item,i) in navList"
					:key="i"
					@click="toDetail(item,i)"
				>
					{{ item.name }}
				</div>
			</div>
			<!-- <div class="login">
				<div v-if="!isLogin" class="btn">
					<div @click="login">登录</div>
					<div @click="login('add')">注册</div>
				</div>
				<div v-else class="loginOut">
					<el-dropdown trigger="hover">
						<div class="avatar-wrapper">
							<el-avatar src="https://www.aliboxx.com/uploadfile/avatar/8.png"></el-avatar>
							<span style="font-size: 12px;margin-left:10px">{{ name }}</span>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="info">
								<span>个人信息</span>
							</el-dropdown-item>
							<el-dropdown-item divided @click.native="logout">
								<span>退出登录</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import { menus } from '@/utils/info.js';
import {getToken, register} from '@/api/user';
import {sendMobileVerifyCode, compareVerifycode, resetpass} from "@/api/user";

export default {
	name: 'Nav',
	data() {
		return {
			navList: menus,
			register: {},
			isLogin: null,
			mobile: '18888888888'
		};
	},
	computed: {
		...mapGetters(["avatar", "name", "roles", "isPay"]),
	},
	mounted() {
		// this.isLogin = localStorage.getItem("token") ? true : false
	},

	methods: {
		handlePush() {
			if(this.$route.path != '/home'){
				this.$router.push({ path: '/home' })
			}
		},
		logout() {
			this.$message.success('退出成功')
			localStorage.removeItem('token')
			this.isLogin = false
			this.$router.replace({
				path: '/login'
			})
		},
		login(e) {
			if (e == 'add') {
				this.$router.push('/register')
			} else {
				this.$router.push('/login')
			}
		},
		info() {
			this.$router.push({
				path: '/info'
			})
		},
		toDetail(item, i) {
			if(this.$route.path != item.path){
				this.$router.push({ path: item.path })
			}
		},

	},
};
</script>

<style lang="scss" scoped>
.nav {
	position: fixed;
	top: 0;
	height: 71px;
	z-index: 100;
	background-color: rgb(159, 197, 232);
	width: 100vw;
	margin: 0 auto;
	box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
	.container {
		height: 100%;
		margin: 0 auto;
		display: flex;
		position: relative;
		.logo{
			margin-right: 50px;
			width: 197px;
			height: 61px;
			position: relative;
			top: 4px;
			left: 3px;
		}
		.menu {
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			height: 100%;
			font-size: 20px !important;
			.menu-item{
				width: 140px;
				height: 58px;
				line-height: 58px;
				text-align: center;
				cursor: pointer;
			}
			.menu-item:hover {
				width: 140px;
				text-align: center;
				cursor: pointer;
				color: white;
				border-bottom: 3px solid white;
			}
		}

		.login {
			position: absolute;
			right: -5%;
			display: flex;

			.loginOut {
				line-height: 68px;

				.user-avatar {
					cursor: pointer;
					width: 20px;
					height: 20px;
					border-radius: 10px;
					margin: -5px 0 0 5px;
				}

				.avatar-wrapper {
					display: flex;
					align-items: center;
					height: 68px;
				}
			}
			.btn {
				display: flex;
				div {
					width: 50px;
					height: 68px;
					line-height: 68px;
					text-align: center;
					cursor: pointer;
				}
			}
		}
	}

	// justify-content: center;

	// align-items: center;

	.active {
		font-weight: 500;
		color: white;
		border-bottom: 3px solid white;
	}
}
</style>
