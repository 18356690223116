<template>
	<div class="comp_container">
		<div class="comp_desc">
			<div class="comp_desc_cn">{{desc_cn}}</div>
			<div class="comp_desc_en">{{desc_en}}</div>
		</div>
		<el-carousel :interval="5000" arrow="always" height="387px" class="comp_carousel">
			<el-carousel-item v-for="(item,i) in swiperList" :key="i">
				<el-image :src="item.img" alt="" style="width:100%;height:100%" fit="cover"/>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
import { me_info } from '@/utils/info.js';

export default {
	name: 'Banner',
	components: {},
	props: {},
	watch: {},
	data() {
		return {
			swiperList: [
				{
				  img: require("@/assets/banner/-33508.jpg"),
				}, {
				  img: require("@/assets/banner/-33507.jpg"),
				},
			],
		};
	},
	computed: {
		desc_cn: () => me_info.summary_cn,
		desc_en: () => me_info.summary_en,
	},
	mounted() {

	},
	created() {

	},

	methods: {},
};
</script>

<style lang="scss" scoped>
.comp_container{
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	top: 71px;
	.comp_desc{
		position: absolute;
		width: 570px;
		height: 243px;
		top: 89px;
		z-index:5;
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 2px;
		display: flex;
		flex-direction: column;
		align-items: center;
		.comp_desc_cn{
			width: 478px;
			height: 87px;
			line-height: 87px;
			background-color: rgba(61, 133, 198, 0.76);
			color: white;
			font-size: 36px;
			text-align: center;
			margin-top: 33px;
			
			border-radius: 2px;

		}
		.comp_desc_en{
			margin-top: 10px;
			height: 90px;
			width: 511px;
			word-wrap: break-word;
			font-family: Microsoft YaHei;
			color: black;
			font-size: 26px;
			text-align: center;
			line-height: 1.5;
			font-weight: bold;
		}
	}
	.comp_carousel{
		width: 100%;
	}
}
</style>
