export const menus = [
	{
		"id": "1000",
		"name": "首页",
		"path": "/home",
	}, {
		"id": "2000",
		"name": "关于我们",
		"path": "/about",
	}, {
		"id": "3000",
		"name": "新闻资讯",
		"path": "/news",
	}, {
		"id": "4000",
		"name": "产品服务",
		"path": "/prod",
	}, {
		"id": "5000",
		"name": "联系我们",
		"path": "/info",
	}
];
export const me_info = {
	"id": "2001",
	"title": "国广（上海）信息科技有限公司",
	"web": "www.gmgft.com",
	"address": "北京市广渠路42号院1号楼4层401",
	"telephone": "010-87745956",
	"fax": "010-87745956",
	"email": "service@gmgft.com",
	"icp": "沪ICP备19035647号-1",
	"summary_cn": "发现投资机会，揭示投资风险",
	"summary_en": "Discovering Investment Opportunities and Revealing Investment Risks",
	"descs": [
		"国广（上海）信息科技有限公司（简称：国广财经）致力于成为中国最具规模和权威的全球财经新闻及商业数据服务商。",
		"国广财经立足于国广环球传媒控股有限公司旗下雄厚的媒体和资讯实力，利用云计算、大数据、人工智能等先进技术，聚合“媒体、金融、科技”的力量，搭建金融信息服务平台，致力于为客户提供全面、优质、高效的金融服务，向参与全球证券、外汇与货币、固定收益、大宗商品等市场的机构和投资者，提供财经资讯、研究报告、行情数据、宏观及行业经济数据、企业数据等金融信息及分析工具，为研究、交易、投资及风险管理提供信息支持，为金融监管机构提供决策参考和政策发布渠道。"
	],
	"images": [
		"10105898.jpg",
		"10059116.jpg",
		"10019060.jpg",
		"10019042.jpg",
	]
};
export const news_info = [
	{
		"id": "3001",
		"title": "清华经管EMBA校友会参观访问国广财经",
		"create": "2019年9月6日 16:24",
		"view": 203,
		"collection": false,
		"img": "10019042.jpg",
		"descs": [
			"2019年8月27日下午，清华大学经管学院EMBA校友会来到国广财经北京办公区进行参观访问。",
			"下午2点，参访团成员一行15人到访。",
			"首先，由国广财经总经理孟东炜先生向大家介绍了国广财经的平台产品和战略目标，随后，孟东炜先生基于国广财经的金融、数据、媒体、科技等方面聚合研究方法，与大家分享了国际金融发展态势，并深入分析了近期金融行业的经典案例。",
			"大家就金融信息行业的发展展开了热烈的讨论，从各自领域角度对国广财经这一金融信息平台给出了发展、合作建议。",
		],
		"images": [
			"10019060.jpg",
			"10019066.jpg",
			"10019095.jpg",
			"10019042.jpg",
		]
	}, {
		"id": "3002",
		"title": "国广财经提交商标注册并完成备案申请",
		"create": "2019年9月10日 14:48",
		"view": 213,
		"collection": false,
		"img": "10221733.jpg",
		"descs": [
			"2019年6月，国广财经将中文名称、英文名称、图形Logo作为专属商标提交注册申请，共申请3个标识在7类类别中，共计21枚商标。7月23日，国广财经所提交的商标注册信息已录入商标系统，正式使用。",
		],
		"images": [
			"9916119.png",
		]
	}, {
		"id": "3003",
		"title": "国广（杭州）通过2019年度第二批中国人工智能小镇项目评审",
		"create": "2019年9月9日 14:32",
		"view": 323,
		"collection": false,
		"img": "10059116.jpg",
		"descs": [
			"7 月 27 日，“2019 年度第二批中国人工智能小镇项目（含区块链项目）评审、2019 年度第二批中国（杭州）5G 创新园项目评审”在杭州市的中国人工智能小镇举行，此次参与评审项目共计 132 个。",
			"国广财经通过杭州分公司提交的项目“可追溯的保护数据内容的数据商品交易平台”，成功通过2019年度第二批中国人工智能小镇项目评审，并排名第一。",
			"根据《中国（杭州）人工智能小镇项目评审管理办法》（杭科（创）管【2018】42 号）规定，经项目征集、资格审查、项目初审、评审答辩，通过浙江杭州未来科技城（海创园）管委会审定， 2019 年度第二批中国人工智能小镇项目（含区块链项目）评审、2019 年度第二批中国（杭州）5G 创新园项目评审的结果如下，予以公示：",
		],
		"images": [
			"10059116.jpg",
			"10059117.jpg",
			"10059118.jpg",
		]
	}, {
		"id": "3004",
		"title": "庆祝国广财经北京办公区开业大吉",
		"create": "2019年9月11日 10:01",
		"view": 325,
		"collection": false,
		"img": "10105898.jpg",
		"descs": [
			"2019年4月4日，国广财经北京办公区举行了隆重的开业典礼。",
			"来自政府机构、高校、金融、媒体、科技公司的领导和来宾参加了开业仪式。",
		],
		"images": [
			"10105900.jpg",
			"10105899.jpg",
			"10105901.jpg",
			"10105898.jpg",
			"10105902.jpg",
		],
	},
];
export const prod_info = {
	"id": "4001",
	"image": "12131772.jpg",
};

